import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { PublicRoutes } from '../Routes';
import { magic } from '../../lib/magic';
import { useEffect, useState } from 'react';
import useMagic from '../../hooks/useMagic';
import LoadingComponent from '../../components/LoadingComponent';
import { Center } from '@chakra-ui/react';

/**
 * AuthGuard component to protect routes based on user authentication state.
 *
 * This component uses the Redux state to check if the user is authenticated
 * by verifying if the user's email is present. If the user is authenticated,
 * it renders the child components using <Outlet />. Otherwise, it redirects
 * the user to the public home route.
 *
 * @returns {JSX.Element} - The <Outlet /> component if authenticated, otherwise <Navigate /> to the home route.
 */
export const AuthGuard = () => {
	const { loginWithMagicLink } = useMagic();
	const [loading, setLoading] = useState(true);
	const [magicLinkSession, setMagicLinkSession] = useState(null);
	const userState = useSelector(store => store.user);

	useEffect(() => {
		const initializeMagic = async () => {
			const magicResponse = await magic.user.isLoggedIn();
			if (!magicResponse) {
				try {
					const loginWithMagicResponse = await loginWithMagicLink(
						userState?.email,
						false,
						!userState?.walletAddress,
					);
					setMagicLinkSession(loginWithMagicResponse);
					setLoading(false);
				} catch (error) {
					setMagicLinkSession(false);
					setLoading(false);
				}
			} else {
				setMagicLinkSession(true);
				setLoading(false);
			}
		};
		initializeMagic();
	});
	if (loading) {
		return (
			<Center h='100vh' w='100vw'>
				<LoadingComponent />
			</Center>
		);
	}
	if (magicLinkSession && userState.email.length > 0) {
		return <Outlet />;
	} else {
		return <Navigate replace to={`${PublicRoutes.Home}`} />;
	}
};

export default AuthGuard;
