import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { PublicRoutes } from '../Routes';

/**
 * RedirectGuard is a functional component that checks the user's authentication state.
 * If the user is not authenticated (userId is empty), it renders the child components (Outlet).
 * If the user is authenticated (userId is not empty), it redirects to the home page.
 *
 * @returns {JSX.Element} The Outlet component if the user is not authenticated, otherwise the Navigate component.
 */
export const RedirectGuard = () => {
	const userState = useSelector(store => store.user);
	return !userState.userId.length ? (
		<Outlet />
	) : (
		<Navigate to={`${PublicRoutes.Home}`} />
	);
};

export default RedirectGuard;
